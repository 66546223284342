import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IPairDetail} from "../../../../shared/models/pair-detail.model";
import {
  calculateETA,
  calculateETADate,
  dayjsToNgbDate,
  dayjsToNgbTime,
  extractSeatingConfigurations,
  getTimestamp,
  minutesToHHmm,
  ngbDateToDayjs,
  ngbDateToFormat,
  ngbTimeToDayjs,
  objectsAreEqual
} from "../../../../shared/utils/utils";
import * as dayjs from "dayjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ILegDelaysLogBulkModel, ILegDelaysLogModel} from "../../../../shared/models/leg-delays-log.model";
import {ISimpleData} from "../../../../shared/models/simpleData.model";
import {IGenericContainerObject} from "../../../../shared/models/genericContainerObject.model";
import {GeneralSettingsService} from "../../../../services/general-settings.service";
import {debounceTime, firstValueFrom, forkJoin, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MinutesFromTimePipe} from "../../../../shared/pipes/minutes-from-time.pipe";
import {ILegsModel} from "../../../../shared/models/legs.model";
import {ITransferFile} from "../../../../shared/models/transfer-file.model";
import {TransferFileService} from "../../../../services/transfer-file.service";
import {LegService} from "../../../../services/leg.service";
import {ToastService} from "../../../../services/toast.service";
import {LegDelaysLogService} from "../../../../services/leg-delays-log.service";
import {IAirportContact} from "../../../../shared/models/airport-contact.model";
import {AirportsService} from "../../../../services/airports.service";

export type TimeType = 'ATD' | 'ATA' | 'LAND' | 'TOFF' | 'ETA';

@Component({
  selector: 'app-enter-times',
  templateUrl: './enter-times.component.html',
  styleUrls: ['./enter-times.component.scss']
})
export class EnterTimesComponent implements OnInit, OnDestroy {

  @Input() pair: IPairDetail;
  form: FormGroup;
  initLandingTime: Date;
  initAta: Date;
  initAtd: Date;
  delayCodes?: ISimpleData[];
  delayCodesKV: IGenericContainerObject<ISimpleData> = {};
  calculateDelaySubject = new Subject<void>();
  calculate$ = this.calculateDelaySubject.pipe(debounceTime(100));
  maxDepartureDelayMinutes = 0;
  currentDepartureDelayMinutes = 0;
  unsubscribe = new Subject();
  delaysToBeSaved: ILegDelaysLogBulkModel;
  isBusy = false;
  originalData: Record<string, any>;
  seatingConfigurations: IGenericContainerObject<string> = {};
  passengerClasses: ISimpleData[];
  paxContainer: IGenericContainerObject<ISimpleData> = {};

  constructor(private fb: FormBuilder, private generalSettingsService: GeneralSettingsService, private minutesFromTimePipe: MinutesFromTimePipe, private transferFileService: TransferFileService, private legService: LegService, private toastService: ToastService, private legDelayService: LegDelaysLogService, private airportService: AirportsService) {
    forkJoin([this.generalSettingsService.getDelayCodes(), this.generalSettingsService.getPassengerClasses()]).subscribe(([delayCodes, classes]) => {
      this.passengerClasses = classes;
      this.delayCodes = delayCodes;
      if (this.delayCodes?.length) {
        for (const delayCode of this.delayCodes) {
          this.delayCodesKV[delayCode.id] = delayCode;
        }
      }
      this.createForm();
      this.patchForm();
      this.calculate$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.calculateMaxCurrentDelays();
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this.calculateDelaySubject.complete();
  }

  ngOnInit(): void {}


  createForm() {
    const actualPassengersFormGroup = new FormGroup({});
    this.passengerClasses.forEach((item: ISimpleData) => {
      if (item?.code) {
        actualPassengersFormGroup.addControl(item.code, new FormGroup({
          code: new FormControl(item.code, Validators.required),
          description: new FormControl(null, Validators.pattern(/^\d+$/))
        }));
      }
    });

    this.form = this.fb.group({
      actualPassengers: actualPassengersFormGroup,
      atdDate: [null],
      atdTime: [null],
      totDate: [null],
      totTime: [null],
      lndDate: [null],
      lndTime: [null],
      ataDate: [null],
      ataTime: [null],
      etaDate: [null],
      etaTime: [null],
      si: [null],
      departureDelaysNew: new FormGroup({
        departure1: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure2: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure3: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
        departure4: new FormGroup({
          delayCode: new FormControl(null),
          time: new FormControl(null, Validators.pattern(/^([0-9]+|[0-9]{2,}):([0-5][0-9])$/))
        }),
      }),
    });

    this.form.get('atdDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged();
      this.calculateDelaySubject.next();
      this.localCalculateETA();
    });
    this.form.get('atdTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.actualTimeChanged();
      this.calculateDelaySubject.next();
      this.localCalculateETA();
    });

    this.form.get('totDate').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.localCalculateETA();
    });
    this.form.get('totTime').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.localCalculateETA();
    });
  }

  patchForm() {
    const atdMoment = this.pair.departureLeg?.atd ? dayjs.utc(this.pair.departureLeg?.atd) : null;
    const totMoment = this.pair.departureLeg?.takeOffTime ? dayjs.utc(this.pair.departureLeg?.takeOffTime) : null;
    const lndMoment = this.pair.arrivalLeg?.landingTime ? dayjs.utc(this.pair.arrivalLeg?.landingTime) : null;
    const ataMoment = this.pair.arrivalLeg?.ata ? dayjs.utc(this.pair.arrivalLeg?.ata) : null;

    const seatConfigs = extractSeatingConfigurations(this.pair.departureLeg.seatingConfiguration);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);
      this.seatingConfigurations[code] = amount;
    });
    this.paxContainer = this.calcPaxData(this.pair.departureLeg ? this.pair.departureLeg.pax : null);

    this.form?.patchValue({
      atdDate: atdMoment && atdMoment.isValid() ? dayjsToNgbDate(atdMoment) : dayjsToNgbDate(dayjs.utc(this.pair.departureLeg?.tod)),
      atdTime: atdMoment && atdMoment.isValid() ? dayjsToNgbTime(atdMoment) : null,
      totDate: totMoment && totMoment.isValid() ? dayjsToNgbDate(totMoment) : dayjsToNgbDate(dayjs.utc(this.pair.departureLeg?.tod)),
      totTime: totMoment && totMoment.isValid() ? dayjsToNgbTime(totMoment) : null,
      lndDate: lndMoment && lndMoment.isValid() ? dayjsToNgbDate(lndMoment) : dayjsToNgbDate(dayjs.utc(this.pair.arrivalLeg?.toa)),
      lndTime: lndMoment && lndMoment.isValid() ? dayjsToNgbTime(lndMoment) : null,
      ataDate: ataMoment && ataMoment.isValid() ? dayjsToNgbDate(ataMoment) : dayjsToNgbDate(dayjs.utc(this.pair.arrivalLeg?.toa)),
      ataTime: ataMoment && ataMoment.isValid() ? dayjsToNgbTime(ataMoment) : null,
      actualPassengers: this.paxContainer
    });

    if (this.pair.departureLeg?.departureDelays) {
      const depDelays: ILegDelaysLogModel[] = Object.values(this.pair.departureLeg?.departureDelays);
      for (let i = 0; i < Math.min(depDelays.length, 4); i++) {
        const delay = depDelays[i];
        const ctrl = (this.form?.get("departureDelaysNew").get('departure' + (i + 1)) as FormGroup);
        ctrl.patchValue({
          delayCode: delay.delayCodeId,
          time: delay.minutes,
        });
      }
    }

    setTimeout(() => {
      this.originalData = { ...this.form.value };
    }, 50);

  }

  calcPaxData(seatConfig: string | null | undefined): IGenericContainerObject<ISimpleData> {

    const paxContainer: IGenericContainerObject<ISimpleData> = {};
    if (!seatConfig) {
      return paxContainer;
    }

    const passClasses = this.passengerClasses ? this.passengerClasses : [];

    const seatConfigs = extractSeatingConfigurations(seatConfig);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);

      const title = passClasses.find((cls) => cls.code === code)?.code;

      if (title) {
        paxContainer[code] = {code, description: amount};
      }
    });

    return paxContainer;
  }

  setCurrentDateTime(type: TimeType) {
    switch (type) {
      case "LAND":
        this.form.patchValue({
          lndTime: dayjsToNgbTime(dayjs.utc()),
          lndDate: dayjsToNgbDate(dayjs.utc())
        });
        break;
      case "TOFF":
        this.form.patchValue({
          totTime: dayjsToNgbTime(dayjs.utc()),
          totDate: dayjsToNgbDate(dayjs.utc())
        });
        break;
      case "ATA":
        this.form.patchValue({
          ataTime: dayjsToNgbTime(dayjs.utc()),
          ataDate: dayjsToNgbDate(dayjs.utc())
        });
        break;
      case "ATD":
        this.form.patchValue({
          atdTime: dayjsToNgbTime(dayjs.utc()),
          atdDate: dayjsToNgbDate(dayjs.utc())
        });
        break;
      case "ETA":
        this.form.patchValue({
          etaTime: dayjsToNgbTime(dayjs.utc()),
          etaDate: dayjsToNgbDate(dayjs.utc())
        });
        break;
    }
  }

  get departureDelayListNew(): Record<string, any> {
    const g = this.form?.get('departureDelaysNew') as FormGroup;

    return g && g.controls ? g.controls : {};
  }

  calculateMaxCurrentDelays(autoUpdate = true) {
    this.currentDepartureDelayMinutes = 0;
    this.maxDepartureDelayMinutes = 0;
    if (this.form.getRawValue().atdDate && this.form.getRawValue().atdTime) {
      const time = ngbTimeToDayjs(this.form.getRawValue().atdTime).format('HH:mm').split(':');
      this.maxDepartureDelayMinutes = ngbDateToDayjs(this.form.getRawValue().atdDate).utc(true).hour(+time[0]).minute(+time[1]).diff(dayjs(this.pair.departureLeg?.std).utc(), 'minutes');
    }
    for (const delay of Object.values(this.form.getRawValue().departureDelaysNew) as any[]) {
      if (!delay?.time) {
        continue;
      }
      const minutes = typeof delay.time === 'number' ? delay.time : this.minutesFromTimePipe.transform(delay.time);
      this.currentDepartureDelayMinutes += minutes;
    }
    if (!autoUpdate) {
      return;
    }
    let dataChanged = false;
    if (this.maxDepartureDelayMinutes !== this.currentDepartureDelayMinutes && this.maxDepartureDelayMinutes > 0) {
      this.form.get('departureDelaysNew').patchValue({
        departure1: {
          delayCode: this.form.get('departureDelaysNew').value.departure1.delayCode || Object.values(this.delayCodes).filter((item) => item.code === '99')[0].id,
          time: this.maxDepartureDelayMinutes,
        }
      });
      dataChanged = true;
    }
    if (dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  actualTimeChanged() {
    this.form.get('departureDelaysNew').patchValue({
      departure1: {
        time: '',
      },
      departure2: {
        time: '',
      },
      departure3: {
        time: '',
      },
      departure4: {
        time: '',
      }
    });
  }

  getRemainingDelay() {
    this.calculateMaxCurrentDelays(false);
    let dataChanged = false;
    if (this.maxDepartureDelayMinutes > this.currentDepartureDelayMinutes) {
      const departureTimeInputs = (this.form.get('departureDelaysNew') as FormGroup).controls;
      let foundFreeCell = false;
      for (const input of Object.values(departureTimeInputs)) {
        if (!input?.value?.time?.length || input.invalid || input.value.time === '00:00') {
          input.patchValue({time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes});
          foundFreeCell = true;
          break;
        }
      }
      if (!foundFreeCell) {
        departureTimeInputs['departure4'].patchValue({time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes + this.minutesFromTimePipe.transform(departureTimeInputs['departure4'].value.time)});
      }
      dataChanged = true;
    }
    if (dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  saveClicked() {
    if (this.isBusy || this.form.invalid || this.maxDepartureDelayMinutes > 0 && this.currentDepartureDelayMinutes > this.maxDepartureDelayMinutes) {
      return;
    }
    if (objectsAreEqual(this.originalData, this.form.value)) {
      this.toastService.showSuccess('Data are unchanged');
      return;
    }
    this.isBusy = true;
    const val = this.form.value;
    const arrivalLeg = this.pair.arrivalLeg;
    const departureLeg = this.pair.departureLeg;
    const ataTime = ngbTimeToDayjs(val.ataTime);
    const atdTime = ngbTimeToDayjs(val.atdTime);
    const landTime = ngbTimeToDayjs(val.lndTime);
    const totTime = ngbTimeToDayjs(val.totTime);
    const newArrivalLeg: ILegsModel = {
      ...arrivalLeg,
      ata: val.ataTime && val.ataDate ? ngbDateToDayjs(val.ataDate).hour(ataTime.hour()).minute(ataTime.minute()).second(ataTime.second()).millisecond(0).toDate() : null,
      landingTime: val.lndTime && val.lndDate ? ngbDateToDayjs(val.lndDate).hour(landTime.hour()).minute(landTime.minute()).second(landTime.second()).millisecond(0).toDate() : null
    };
    const newDepartureLeg: ILegsModel = {
      ...departureLeg,
      atd: val.atdTime && val.atdDate ? ngbDateToDayjs(val.atdDate).hour(atdTime.hour()).minute(atdTime.minute()).second(atdTime.second()).millisecond(0).toDate() : null,
      takeOffTime: val.totTime && val.totDate ? ngbDateToDayjs(val.totDate).hour(totTime.hour()).minute(totTime.minute()).second(totTime.second()).millisecond(0).toDate() : null
    };
    if (val.actualPassengers) {
      const finalSeatConfig: string | undefined = Object.values(val.actualPassengers).map((item: ISimpleData | any) => {
        if (item?.code && item?.description) {
          return `${item?.code}${item?.description}`;
        }
        return null;
      }).filter(item => item).join('') || '';

      if (finalSeatConfig) {
        newDepartureLeg.pax = finalSeatConfig;
      }
    }
    const observables: Observable<any>[] = [];

    if (val.etaDate?.month && val.etaTime?.hour) {
      newDepartureLeg.eta = dayjs.utc(ngbDateToFormat(val.etaDate, 'DDMMYYYY') + ngbTimeToDayjs(val.etaTime).format('HHmm'), 'DDMMYYYYHHmm').toDate();
    }

    if (!newDepartureLeg.eta) {
      const etaDate = calculateETADate(newDepartureLeg);
      const etaTime = calculateETA(newDepartureLeg, val.atdDate, val.atdTime, val.totTime, newDepartureLeg.etd).split(':');
      newDepartureLeg.eta = dayjs.utc(etaDate.format('DDMMYYYY') + etaTime, 'DDMMYYYYHH:mm').toDate();
    }

    if (val.lndTime?.hour && val.lndDate?.month || val.ataDate?.month && val.ataTime?.hour) {
      observables.push(this.legService.saveLeg(newArrivalLeg));
    }
    const newSeatingConfig: string | undefined = Object.values(val.actualPassengers).map((item: ISimpleData | any) => {
      if (item?.code && item?.description) {
        return `${item?.code}${item?.description}`;
      }
      return null;
    }).filter(item => item).join('') || '';

    const oldSeatingConfig: string | undefined = Object.values(this.paxContainer).map((item: ISimpleData | any) => {
      if (item?.code && item?.description) {
        return `${item?.code}${item?.description}`;
      }
      return null;
    }).filter(item => item).join('') || '';

    if (val.totTime?.hour && val.totDate?.month || val.totDate?.month && val.totTime?.hour || newSeatingConfig !== oldSeatingConfig) {
      observables.push(this.legService.saveLeg(newDepartureLeg));
    }
    if (observables.length) {
      console.log('Before Saving Check1: ', this.pair.arrivalLeg.landingTime, newArrivalLeg.landingTime)
      this.initLandingTime = this.pair.arrivalLeg.landingTime;
      this.initAta = this.pair.arrivalLeg.ata;
      this.initAtd = this.pair.departureLeg.atd;
      forkJoin(observables).subscribe(() => {
        this.saveDelays(newDepartureLeg).subscribe(() => {
          this.toastService.showSuccess('Legs saved successfully');
          console.log('Before Saving Check2: ', this.pair.arrivalLeg.landingTime, newArrivalLeg.landingTime)
          this.createMVT(newArrivalLeg, newDepartureLeg);
          this.isBusy = false;
        });
      });
    } else {
      this.toastService.showSuccess('Data are unchanged');
      this.isBusy = false;
    }
  }

  saveDelays(departureLeg: ILegsModel): Observable<ILegDelaysLogBulkModel> {
    const objToSend: ILegDelaysLogBulkModel = {
      legId: departureLeg.id,
      arrivalDelays: [],
      departureDelays: [],
    };
    for (const key in this.form.getRawValue().departureDelaysNew) {
      const val = this.form.getRawValue().departureDelaysNew[key];
      if (val.time && val.delayCode) {
        objToSend.departureDelays.push({
          delayCodeId: Number(val.delayCode),
          minutes: this.minutesFromTimePipe.transform(val.time),
        });
      }
    }
    this.delaysToBeSaved = objToSend;
    return this.legDelayService.saveLegDelayLogBulk(objToSend);
  }

  async createMVT(arrivalLeg: ILegsModel, departureLeg: ILegsModel) {
    const lineSeparator = '\n'
    let contentOfAD: string;
    let contentOfAA: string;
    let delaysText = '';
    let paxAmount = 0;
    const contactCompaniesArrivalLeg: IAirportContact[] = await firstValueFrom(this.airportService.fetchAirportContactCompanies(arrivalLeg.arrivalStationId));

    if (!dayjs(this.initAtd).isSame(dayjs(departureLeg.atd)) && departureLeg.takeOffTime !== null) {
      //Check for delays
      if (this.delaysToBeSaved.departureDelays.length) {
        let delayCodeDep = '';
        let delayMinutesDep = ''
        for (const delay of this.delaysToBeSaved.departureDelays) {
          delayCodeDep = delayCodeDep + this.delayCodes.find((dl) => dl.id === delay.delayCodeId).code + '/';
          delayMinutesDep = delayMinutesDep + minutesToHHmm(+delay.minutes) + '/';
        }
        delayMinutesDep = delayMinutesDep.slice(0, delayMinutesDep.length - 1) //remove the '/' in the end
        delaysText = 'DL' + delayCodeDep + delayMinutesDep;
      }

      //check for Pax
      if (departureLeg.pax) {
        const paxOfLegDep = extractSeatingConfigurations(departureLeg.pax);
        for (const pax of paxOfLegDep) {
          paxAmount = +pax.slice(1) + paxAmount;
        }
      }
      contentOfAD = `MVT${lineSeparator}${(departureLeg.airlineDesignator) + (departureLeg.flightNumber)}/${dayjs(departureLeg.std).format('DD').toUpperCase()}.${departureLeg.acRegistration}.${departureLeg.departureStation}${lineSeparator}AD${dayjs(departureLeg.atd).utc().format('DD').toUpperCase()}${dayjs(departureLeg.atd).utc().format('HHmm').toUpperCase()}/${dayjs(departureLeg.takeOffTime).utc().format('DD').toUpperCase()}${dayjs(departureLeg.takeOffTime).utc().format('HHmm').toUpperCase()} EA${dayjs(departureLeg.eta).utc().format('HHmm').toUpperCase()} ${departureLeg.arrivalStation}${lineSeparator}${delaysText !== '' ? delaysText + lineSeparator : ''}${paxAmount > 0 ? 'PX' + String(paxAmount) : ''}${this.form.value.si ? lineSeparator + 'SI ' + this.form.value.si : '' }`;
      const nameOfAD = 'AD' + dayjs(departureLeg.atd).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' + 'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAD, fileContent: contentOfAD}
      console.log('Created AD MVT FILE : ', contentOfAD )
      this.transferFileService.sendFile(file).subscribe();
      const sitaFile = this.createSITAFile(contactCompaniesArrivalLeg, contentOfAD);
      console.log('Created SITA FILE : ', sitaFile )
      this.transferFileService.sendFile({
        fileName: `${dayjs.utc().format('DDMMYYYY HH_mm_ss')}.snd`,
        fileContent: sitaFile
      }).subscribe();
    }

    console.log('Comparing Arrival Times',this.pair.arrivalLeg.landingTime, arrivalLeg.landingTime, this.initLandingTime ,arrivalLeg.ata);
    if ((!dayjs(this.initLandingTime).isSame(dayjs(arrivalLeg.landingTime)) || (!dayjs(this.initAta).isSame(dayjs(arrivalLeg.ata)))) && arrivalLeg.ata !== null && arrivalLeg.landingTime !== null) {
      contentOfAA = `MVT${lineSeparator}${(arrivalLeg.airlineDesignator) + (arrivalLeg.flightNumber)}/${dayjs(arrivalLeg.std).utc().format('DD').toUpperCase()}.${arrivalLeg.acRegistration}.${arrivalLeg.arrivalStation}${lineSeparator}AA${dayjs(arrivalLeg.ata).utc().format('DD').toUpperCase()}${dayjs(arrivalLeg.ata).utc().format('HHmm').toUpperCase()}/${dayjs(arrivalLeg.landingTime).utc().format('DD').toUpperCase()}${dayjs(arrivalLeg.landingTime).utc().format('HHmm').toUpperCase()}`;
      const nameOfAA = 'AA' + dayjs(arrivalLeg.ata).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' + 'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAA, fileContent: contentOfAA}
      console.log('Created AA MVT FILE : ', contentOfAA );
      this.initLandingTime = arrivalLeg.landingTime;
      this.transferFileService.sendFile(file).subscribe();
      const sitaFile = this.createSITAFile(contactCompaniesArrivalLeg, contentOfAA);
      console.log('Created SITA FILE : ', sitaFile )
      this.transferFileService.sendFile({
        fileName: `${dayjs.utc().format('DDMMYYYY HH_mm_ss')}.snd`,
        fileContent: sitaFile
      }).subscribe();
    }
  }

  createSITAFile(contactCompanies: IAirportContact[], mvtMessage: string): string {
    const data: {
      priority?: string;
      destination?: string[];
      type?: string;
    } = {
      priority: 'QU',
      destination: contactCompanies.map((comp) => {
        const destinations: string[] = [];
        if (comp.sita1) {
          destinations.push('STX,' + comp.sita1)
        }
        if (comp.sita2) {
          destinations.push('STX,' + comp.sita2)
        }
        if (comp.email) {
          destinations.push('INTERNET,,' + comp.email)
        }
        return destinations.join('\n');
      }),
      type: 'MVT'
    }
    const message: string[] = ['=PRIORITY',
      data.priority];
    if (data.destination?.length) {
      message.push('=DESTINATION TYPE B');
      message.push(data.destination.join('\n'));
    }
    message.push('=SMI', data.type, '=TEXT', mvtMessage.replace('MVT\n', ''));
    return message.join('\n');
  }

  localCalculateETA()
  {
    if (!this.form) {
      return;
    }
    const eta = calculateETA(this.pair?.departureLeg, this.form.getRawValue().atdDate, this.form.getRawValue().atdTime ?? null, this.form.getRawValue().totTime ?? null, this.pair?.departureLeg?.etd ? dayjs(this.pair?.departureLeg?.etd).toDate() : null);
    if (eta && (this.form.get('atdTime').value) ) {
      const formValues = this.form.value;
      let lastGivenDateTimeMoment = formValues.atdDate && formValues.atdTime ? dayjs.utc(ngbDateToFormat(formValues.atdDate, 'DDMMYYYY') + ' ' + ngbTimeToDayjs(formValues.atdTime).format('HH:mm'), 'DDMMYYYY HH:mm') : this.pair.departureLeg.atd ? dayjs.utc(this.pair.departureLeg.atd) : this.pair.departureLeg.etd ? dayjs.utc(this.pair.departureLeg.etd) : dayjs.utc(this.pair.departureLeg.std);
      if (formValues.atdDate && formValues.atdTime) {
        lastGivenDateTimeMoment = dayjs.utc(ngbDateToFormat(formValues.atdDate) + '-' + (ngbTimeToDayjs(formValues?.atdTime)?.format('HH:mm') ?? '00:00'), 'DD.MM.YYYY-HH:mm');
      }
      if (formValues.totTime) {
        lastGivenDateTimeMoment = dayjs.utc(this.calcApplicableButPossibleNextDayDateTime(ngbTimeToDayjs(formValues.totTime).format('HH:mm'), lastGivenDateTimeMoment).toDate());
      }

      const calculatedEta = this.calcApplicableButPossibleNextDayDateTime(eta, lastGivenDateTimeMoment);
      this.form.get('etaTime').setValue(dayjsToNgbTime(calculatedEta));
      this.form.get('etaDate').setValue(dayjsToNgbDate(calculatedEta));
    } else {
      this.form.get('etaTime').setValue(null);
      this.form.get('etaDate').setValue(null);
    }
  }

  private calcApplicableButPossibleNextDayDateTime(currentTimeString: string, lastGivenDateTimeMoment: dayjs.Dayjs): dayjs.Dayjs {
    const currentTimeMoment = dayjs.utc(lastGivenDateTimeMoment.format('YYYY-MM-DD') + '-' + currentTimeString, 'YYYY-MM-DD-HH:mm');
    if (lastGivenDateTimeMoment.isAfter(currentTimeMoment)) {
      return currentTimeMoment.add(1, 'days');
    }
    return currentTimeMoment;
  }
}
