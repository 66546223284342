import { Component, OnInit } from '@angular/core';
import { ILoadingInstruction } from './loading-instruction.model';
import { LoadingContainerType } from '../../../../shared/enums/loading-container-type.enum';
import { EditLoadingInstructionModalComponent } from './edit-loading-instruction-modal/edit-loading-instruction-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

const dummyData: ILoadingInstruction[] = [
  {
    staticText: 'VIE/PAG/140A/C/{weight}/PEM.EAT',
    containerType: LoadingContainerType.PALLET,
    weight: 3700,
    compartment: 2,
    position: 1,
  },
  {
    staticText: 'TRANSIT: ATH/PAG 250A/C/{weight}/AOG.FKT.COMAIL',
    containerType: LoadingContainerType.PALLET,
    weight: 2543,
    compartment: 2,
    position: 2,
  },
  {
    staticText: 'VIE/AKE 24501 IR/B/{weight}',
    containerType: LoadingContainerType.CONTAINER,
    weight: 500,
    compartment: 4,
    position: 1,
    side: 'L'
  },
  {
    staticText: 'VIE/AKE 25001 IR/B/{weight}',
    containerType: LoadingContainerType.CONTAINER,
    weight: 500,
    compartment: 4,
    position: 1,
    side: 'R'
  },
  {
    staticText: 'VIE/AKE 24501 IR/B/{weight}',
    containerType: LoadingContainerType.CONTAINER,
    weight: 500,
    compartment: 4,
    position: 2,
    side: 'L'
  }
];

@Component({
  selector: 'app-loading-info',
  templateUrl: './loading-info.component.html',
  styleUrls: ['./loading-info.component.scss']
})
export class LoadingInfoComponent implements OnInit {

  instructions: ILoadingInstruction[] = dummyData;

  constructor(private modalService: NgbModal) {
    const loadingInstructions = localStorage.getItem('loading-instructions');
    if (loadingInstructions?.length) {
      this.instructions = JSON.parse(loadingInstructions);
    }
  }

  ngOnInit(): void {
  }

  public editInstruction(instruction: ILoadingInstruction) {
    const modalRef = this.modalService.open(EditLoadingInstructionModalComponent, {size: 'lg', centered: true});
    const modal: EditLoadingInstructionModalComponent = modalRef.componentInstance;
    modal.loadingInstruction = instruction;
    modalRef.dismissed.pipe(take(1)).subscribe((reason) => {
      if (reason === true) {
        localStorage.setItem('loading-instructions', JSON.stringify(this.instructions));
      }
    });
  }
}
