<div class="d-flex flex-column skip-invalid-border" *ngIf="pair && form" [formGroup]="form">
  <div class="flight-info roboto-font">{{ pair.arrivalLeg?.airlineDesignator }}{{ pair.arrivalLeg?.flightNumber }} {{ pair.arrivalLeg?.departureStation }} {{ pair.arrivalLeg?.std | date:"HH:mm":"+0000" }} - {{ pair.arrivalLeg?.arrivalStation }} {{ pair.arrivalLeg?.sta | date:"HH:mm":"+0000" }} | {{ pair.arrivalLeg?.acType }}</div>
  <div class="grid dark-blue">
    <div class="time-type">Landing Time</div>
    <input class="light-blue-border" type="text" formControlName="lndDate" ngbDatepicker #d="ngbDatepicker" (focusin)="d.open()" [restoreFocus]="true"/>
    <ngb-timepicker formControlName="lndTime" [spinners]="false" ></ngb-timepicker>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-clock align-self-center ms-1" viewBox="0 0 16 16" (click)="setCurrentDateTime('LAND')">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>

    <div class="horizontal-line"></div>

    <div class="time-type">ATA</div>
    <input class="light-blue-border" type="text" formControlName="ataDate" ngbDatepicker #d2="ngbDatepicker" (focusin)="d2.open()" [restoreFocus]="true"/>
    <ngb-timepicker formControlName="ataTime" [spinners]="false"></ngb-timepicker>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-clock align-self-center ms-1" viewBox="0 0 16 16" (click)="setCurrentDateTime('ATA')">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>
    <div class="horizontal-line"></div>
  </div>


  <div class="flight-info roboto-font mt-2">{{ pair.departureLeg?.airlineDesignator }}{{ pair.departureLeg?.flightNumber }} {{ pair.departureLeg?.departureStation }} {{ pair.departureLeg?.std | date:"HH:mm":"+0000" }} - {{ pair.departureLeg?.arrivalStation }} {{ pair.departureLeg?.sta | date:"HH:mm":"+0000" }} | {{ pair.departureLeg?.acType }}</div>
  <div class="grid dark-blue">
    <div class="time-type">ATD</div>
    <input class="light-blue-border" type="text" formControlName="atdDate" ngbDatepicker #d3="ngbDatepicker" (focusin)="d3.open()" [restoreFocus]="true"/>
    <ngb-timepicker formControlName="atdTime" [spinners]="false" ></ngb-timepicker>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-clock align-self-center ms-1" viewBox="0 0 16 16" (click)="setCurrentDateTime('ATD')">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>

    <div class="horizontal-line"></div>

    <div class="time-type">Take Off Time</div>
    <input class="light-blue-border" type="text" formControlName="totDate" ngbDatepicker #d4="ngbDatepicker" (focusin)="d4.open()" [restoreFocus]="true"/>
    <ngb-timepicker formControlName="totTime" [spinners]="false"></ngb-timepicker>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-clock align-self-center ms-1" viewBox="0 0 16 16" (click)="setCurrentDateTime('TOFF')">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>
    <div class="horizontal-line"></div>

    <div class="time-type">ETA</div>
    <input class="light-blue-border" type="text" formControlName="etaDate" ngbDatepicker #d5="ngbDatepicker" (focusin)="d5.open()" [restoreFocus]="true"/>
    <ngb-timepicker formControlName="etaTime" [spinners]="false"></ngb-timepicker>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-clock align-self-center ms-1" viewBox="0 0 16 16" (click)="setCurrentDateTime('ETA')">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>
    <div class="horizontal-line"></div>
  </div>

  <div class="d-flex flex-column mt-2 dark-blue gap-3">
    <div class="d-flex flex-column gap-3">
      <div class="row">
        <div class="form-field-header">
          Departure Delays
        </div>
      </div>
      <form class="d-flex gap-2" [formGroup]="delay.value"
            *ngFor="let delay of departureDelayListNew | keyvalue; let i = index;">
        <div class="delay-code-select">
          <ng-select formControlName="delayCode" [attr.data-test]="'change-arrival-flight-details-departure-delay-code-' + i">
            <ng-option *ngFor="let code of delayCodes" [value]="code.id" >{{code.code}} {{ code.description }}</ng-option>
            <ng-template ng-option-tmp let-item="item">
              <span class="ng-option-label" [attr.data-test]="'delay-code-' + item">{{ delayCodesKV[item]?.code }} {{ delayCodesKV[item]?.description }}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="delay-time-input">
          <app-time-input formControlName="time" (focusLost)="getRemainingDelay()" [attr.data-test]="'change-arrival-flight-details-departure-delay-time-' + i"></app-time-input>
        </div>
      </form>
    </div>
    <div class="actual-passengers-area justify-self-center skip-invalid-border">
      <div class="actual-passengers d-flex flex-column gap-2 skip-invalid-border text-center" formGroupName="actualPassengers">
        <div class="form-field-header align-self-start">
          Actual Passengers
        </div>
        <div class="d-flex justify-content-between align-items-center text-gray skip-invalid-border" *ngFor="let pClass of passengerClasses" [formGroupName]="pClass.code">
          <div class="passenger-class">{{ pClass.code }} <span>({{seatingConfigurations?.[pClass.code] || '0'}})</span></div>
          <input type="text" class="form-control light-blue-border passenger-input" id="actual-passengers-class-{{pClass.code}}" placeholder=""
                 [attr.data-test]="'actual-passengers-class-' + pClass.code" formControlName="description">
        </div>
      </div>
    </div>
    <div class="justify-self-center">
      <div class="actual-passengers d-flex flex-column gap-2 skip-invalid-border text-center">
        <div class="form-field-header align-self-start">
          Supplementary Information
        </div>
        <div class="d-flex gap-3 justify-content-between align-items-center text-gray skip-invalid-border">
          <div class="align-self-center">SI</div>
          <input type="text" class="form-control light-blue-border" formControlName="si">
        </div>
      </div>
    </div>
    <div class="d-flex flex-column gap-3">
      <div class="row">
        <div class="delay-info-box">
          <ng-container *ngIf="maxDepartureDelayMinutes > 0 && currentDepartureDelayMinutes !== maxDepartureDelayMinutes">
            <div class="row">
              <span class="delay-error">The delay minutes you entered ({{currentDepartureDelayMinutes}}) do not match the current flight delay time ({{ maxDepartureDelayMinutes }}) </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <button [disabled]="form.invalid || isBusy" class="btn button-blue roboto-font" (click)="saveClicked()" data-test="save-times">
    <ng-container *ngIf="!isBusy; else loading">
      Save
    </ng-container>
    <ng-template #loading>
      <app-loading-spinner></app-loading-spinner>
    </ng-template>
  </button>
</div>
