<div class="d-flex flex-column gap-2">
  <div class="p-3">
    <div class="col-12">
      <object data="../../../assets/img/Avitium_Ramp_Watch_Logo.svg" type="image/svg+xml" alt="avitium ramp watch logo" width="100%" height="100px">
        <!-- Für Browser ohne SVG-Unterstützung -->
      </object>
    </div>
  </div>
  <div class="h-divider"></div>
  <div class="d-flex flex-column gap-2 p-3">
    <div class="col-12">
      <form [formGroup]="form" class="d-flex flex-column gap-2">
        <div>
          <label for="userInput" class="form-label dark-blue roboto-font">Email address</label>
          <input type="text" formControlName="username" class="form-control" id="userInput" data-test="username-input">
        </div>
        <div>
          <label for="pwInput" class="form-label dark-blue roboto-font">Password</label>
          <input type="password" formControlName="password" class="form-control" id="pwInput" data-test="password-input">
        </div>
        <div>
          <div style="text-align: right;" class="header-funnel me-2 forgot-pw roboto-font my-3" routerLink="/forgot-password" data-test="forgot-pw-link">
            Forgot password
          </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <p>
        <ngb-alert *ngIf="responseError || (user && user.role !== 'RAMP_AGENT')" type="danger" [dismissible]="false">
          <div class="roboto-font"><strong>Error!</strong> You are not allowed to proceed because either the given credentials are incorrect or you do not have the permission!</div>
        </ngb-alert>
        <ngb-alert *ngIf="!responseError && !user" type="info" [dismissible]="false">
          <div class="roboto-font"><strong>Info!</strong> Please enter your credentials to proceed to the ramp agents application.</div>
        </ngb-alert>
      </p>
    </div>
    <div class="col-12">
      <div class="d-grid">
        <button class="btn button-blue" [disabled]="form.invalid || isBusy" (click)="signIn()">
          <ng-container *ngIf="!isBusy; else loading">
            Sign In
          </ng-container>
          <ng-template #loading>
            <app-loading-spinner></app-loading-spinner>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
</div>
