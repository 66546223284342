<div class="d-flex flex-column gap-2 dark-blue">
  <div class="align-self-center">
    <img src="/content/images/plane-nose.png" alt="plane nose" width="130px">
  </div>
  <div class="info-grid">
    <app-loading-instruction (click)="editInstruction(instruction)" *ngFor="let instruction of instructions" [compartment]="instruction.compartment" [position]="instruction.position" [weight]="instruction.weight" [containerType]="instruction.containerType" [side]="instruction.side" [staticText]="instruction.staticText"></app-loading-instruction>
  </div>
<!--  <div class="d-flex justify-content-between">-->
<!--    <div class="light-blue-border loading-box"><strong>21</strong>P-->
<!--      VIE/PAG/140A/C/<strong>3700</strong>/-->
<!--      PEM.EAT-->
<!--    </div>-->
<!--    <div class="light-blue-border loading-box"><strong>22</strong>P-->
<!--      TRANSIT: ATH/PAG 250A-->
<!--      /C/<strong>2543</strong>/AOG.FKT.COMAIL-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="d-flex justify-content-between">-->
<!--    <div class="light-blue-border loading-box"><strong>41</strong>L-->
<!--                                                                  VIE/AKE 24501 IR/B/<strong>500</strong>-->

<!--    </div>-->
<!--    <div class="light-blue-border loading-box"><strong>41</strong>R-->
<!--                                                                  VIE/AKE 25001 IR/B/<strong>500</strong>-->

<!--    </div>-->
<!--  </div>-->
<!--  <div class="d-flex justify-content-between">-->
<!--    <div class="light-blue-border loading-box"><strong>42</strong>L-->
<!--                                                                  VIE/AKE 24501 IR/B/<strong>400</strong>-->

<!--    </div>-->
<!--  </div>-->
</div>
